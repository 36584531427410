.connectedSortable {
  min-height:200px
}

.js-drop-marker {

  height:100px; background-color:#e3e3e3 !important;
  box-shadow: none;
  border-bottom: none;
  border-top: none;
}

.connectedSortable li {
  cursor: pointer;
  margin-bottom:5px;
}

.js-draggable-well{
  line-height:15px
}

.js-edit-button{
  position:absolute;
  right:4px;
  bottom:-10px;
}

.list-group-item.past {
  box-shadow: 3px 3px red;
}

.list-group-item {
  .vertical-text {
    writing-mode: horizontal-tb;
  }
}

.js-well-quote-number{
  position:absolute;
  right:30px;
  bottom:-10px;
  font-size:12px;
}

@media (min-width: $screen-lg-min) {
 .list-group-item {
   .vertical-text{
     writing-mode: vertical-lr;
     position:absolute;
   }
 }

}
